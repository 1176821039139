export default {
  message: {
    hello: 'hello world',
    apple: 'Apple'
  },
  util: {
    selectDownloadType: 'Select download type',
    pdfFile: 'pdf file',
    excelFile: 'excel file'
  }
}
