
import { defineComponent } from 'vue'
import useBreadcrumb from '@/composable/useBreadcrumb'

export default defineComponent({
  setup() {
    const { breadcrumbList } = useBreadcrumb()

    return {
      breadcrumbList
    }
  }
})
