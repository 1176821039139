import request from '../api/_request'
import MockAdapter from 'axios-mock-adapter'

import { users } from './data/user'

const init = () => {
  // setup mock adapter instance
  const mock = new MockAdapter(request, { delayResponse: 500 })

  // Mock any GET request to /users
  mock.onGet('/users').reply(config => {
    console.log(config)
    return [200, { users }]
  })

  // user login
  mock.onPost('/user/login').reply(({ data }) => {
    const { username, password } = JSON.parse(data)
    const user = users[username]
    if (user && user.password === password) {
      return [200, { token: user.token }]
    } else {
      return [200, { message: 'Account and password are incorrect.' }]
    }
  })

  // user logout
  mock.onPost('/user/logout').reply(() => {
    return [200, { message: 'success' }]
  })
}

export default {
  init
}
