
import { defineComponent } from 'vue'
import HelloGoJs from '../components/HelloGoJs.vue'

export default defineComponent({
  components: {
    HelloGoJs
  },
  setup() {
    return {}
  }
})
