import { createApp } from 'vue'
import i18n from './i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import mock from './mock'
import SvgIcon from '@/components/icon/SvgIcon.vue'
import SvgIconFixedColor from '@/components/icon/SvgIconFixedColor.vue'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

mock.init()

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueClipboard)
  .component('SvgIcon', SvgIcon)
  .component('SvgIconFixedColor', SvgIconFixedColor)
  .mount('#app')

// Navigation Guards
router.beforeEach((to, from, next) => {
  //@ts-ignore
  app.$store.commit('load/SET_LOADING') // 載入動畫
  next()
})
router.afterEach(() => {
  setTimeout(() => {
    //@ts-ignore
    app.$store.commit('load/UNSET_LOADING')
  }, 1100) // 演示用
})
