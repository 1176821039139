<template>
  <section class="app-main">
    <transition name="slide-fade">
      <AppLoader v-if="$store.state.load.isLoading" />
    </transition>
    <transition name="slide-page" mode="out-in">
      <router-view />
    </transition>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppLoader from '@/components/Loader/AppLoader.vue'

export default defineComponent({
  components: {
    AppLoader
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss">
// 載入條漸變
.slide-fade-enter-active {
  transition: all 0.35s cubic-bezier(0.61, 1, 0.88, 1);
}

.slide-fade-leave-active {
  transition: all 0.32s cubic-bezier(0.32, 0, 0.67, 0);
}

.slide-fade-enter-from {
  transform: translateY(25px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

// 頁面漸變
.slide-page-enter-active {
  transition: all 0.4s cubic-bezier(0.61, 1, 0.88, 1);
}

.slide-page-leave-active {
  transition: all 0.4s cubic-bezier(0.32, 0, 0.67, 0);
}

.slide-page-enter-from {
  transform: translateY(-60px);
  opacity: 0;
}
.slide-page-leave-to {
  transform: translateY(60px);
  opacity: 0;
}
</style>
