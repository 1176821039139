<template>
  <div class="custom-menu">
    <div
      class="item-title custom-menu-title"
      :class="{ 'has-active-child': hasActiveChildWhenSidebarHide }"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <SvgIcon class="title-icon" icon="star" @click="$emit('showSidebar')" />
      <span class="item-title-text custom-title-text">{{ title }}</span>
      <div class="add-btn">
        <SvgIcon icon="plus" />
      </div>
    </div>
    <div class="sidebar-menu-item" v-for="item in items" :key="item.title">
      <router-link
        :to="{ name: item.pathName }"
        v-slot="{ isActive, isExactActive }"
      >
        <div
          class="item-title"
          :class="{ 'is-active': isActive || isExactActive }"
        >
          <span class="item-title-text">{{ item.title }}</span>
        </div>
      </router-link>
    </div>
  </div>

  <teleport to="#tooltip-container">
    <transition name="fade">
      <Tooltip v-if="showTooltip" :offsetX="20" :offsetY="-10">
        {{ title }}
      </Tooltip>
    </transition>
  </teleport>
</template>

<script lang="ts">
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { defineComponent, computed, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { Tooltip },
  props: {
    title: String,
    items: Array,
    isSidebarHide: Boolean
  },
  emits: ['showSidebar'],
  setup(props) {
    const route = useRoute()
    const hasActiveChildWhenSidebarHide = computed(() => {
      if (props.isSidebarHide) {
        return props.items?.reduce((acc, item: any) => {
          return item.pathName === route.name ? true : acc
        }, false)
      }
      return false
    })

    // tooltip 相關
    const isHover = ref(false)
    const showTooltip = computed(() => {
      if (props.isSidebarHide && isHover.value) {
        return true
      }
      return false
    })

    return {
      hasActiveChildWhenSidebarHide,
      showTooltip,
      isHover
    }
  }
})
</script>

<style scoped></style>
