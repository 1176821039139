import { ref, onMounted, onUnmounted } from 'vue'

const useDropdown = (xoffset = 0, yoffset = 0) => {
  const btn = ref(null)
  const menu = ref(null)
  const isOpen = ref(false)

  const defaultY = 13

  const setMenuPos = () => {
    // 根據 btn 設定 menu 位置
    menu.value.style.right =
      window.innerWidth -
      btn.value.getBoundingClientRect().right -
      xoffset +
      'px'
    menu.value.style.top =
      btn.value.getBoundingClientRect().bottom + defaultY + yoffset + 'px'
  }

  const toggleDropdown = () => {
    if (!isOpen.value) {
      setMenuPos()
    }
    isOpen.value = !isOpen.value
  }

  const onClick = e => {
    if (!btn.value.contains(e.target) && !menu.value.contains(e.target)) {
      // 點擊外部區域，將關閉 dropdown
      isOpen.value = false
    }
  }

  onMounted(() => {
    window.addEventListener('click', onClick)
  })

  onUnmounted(() => {
    window.removeEventListener('click', onClick)
  })

  return {
    isOpen,
    toggleDropdown,
    btn,
    menu
  }
}

export default useDropdown
