
import { defineComponent } from 'vue'
import HelloTreeView from '../components/HelloTreeView.vue'

export default defineComponent({
  components: {
    HelloTreeView
  },
  setup() {
    return {}
  }
})
