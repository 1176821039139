// throttle 節流
const throttle = (fn, wait) => {
  const callback = fn
  let timerId = null
  // 是否是第一次執行
  let firstInvoke = true

  function throttled(...args) {
    const context = this
    // 如果是第一次觸發，直接執行
    if (firstInvoke) {
      callback.apply(context, args)
      firstInvoke = false
      return
    }
    // 如果定時器已存在，直接返回。
    if (timerId) {
      return
    }
    timerId = setTimeout(() => {
      clearTimeout(timerId)
      timerId = null
      callback.apply(context, args)
    }, wait)
  }

  return throttled
}

export { throttle }
