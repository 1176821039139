import { useStore } from 'vuex'
import { computed } from 'vue'

const useDragBlock = () => {
  const store = useStore()
  const showBlock = computed(() => store.state.drag.isDargEnable)

  return {
    showBlock
  }
}

export default useDragBlock
