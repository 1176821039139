<template>
  <div>
    <h2>Kendo Tree View</h2>
    <HelloTreeView />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HelloTreeView from '../components/HelloTreeView.vue'

export default defineComponent({
  components: {
    HelloTreeView
  },
  setup() {
    return {}
  }
})
</script>
