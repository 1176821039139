import { ref, computed } from 'vue'
import { orderBy } from '@progress/kendo-data-query'
import { saveExcel } from '@progress/kendo-vue-excel-export'

const useGrid = () => {
  const dataItems = ref([])
  const skip = ref(0)
  const take = ref(20)
  const sort = ref([{ field: 'MAC', dir: 'asc' }])
  const columns = ref([
    {
      field: 'IsOnline',
      title: 'IsOnline',
      width: '170px',
      locked: true,
      headerCell: 'myHeader'
    },
    {
      field: 'Ipv4',
      title: 'Ipv4',
      width: '170px',
      locked: true,
      headerCell: 'myHeader'
    },
    { field: 'MAC', title: 'MAC', width: '170px', headerCell: 'myHeader' },
    { field: 'Site', title: 'Site', width: '170px', headerCell: 'myHeader' },
    {
      field: 'NetworkName',
      title: 'NetworkName',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'HostLastOnlineTime',
      title: 'HostLastOnlineTime',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'HostLastOfflineTime',
      title: 'HostLastOfflineTime',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'HostName',
      title: 'HostName',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'HostWorkgroup',
      title: 'HostWorkgroup',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'OSDetail',
      title: 'OSDetail',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'Status',
      title: 'Status',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'Ipv6Aggregate',
      title: 'Ipv6Aggregate',
      width: '170px',
      headerCell: 'myHeader'
    },
    { field: 'Duid', title: 'Duid', width: '170px', headerCell: 'myHeader' },
    { field: 'Iaid', title: 'Iaid', width: '170px', headerCell: 'myHeader' },
    {
      field: 'LinkLocalIpAddress',
      title: 'LinkLocalIpAddress',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'VlanID',
      title: 'VlanID',
      width: '170px',
      headerCell: 'myHeader'
    },
    {
      field: 'SwitchName',
      title: 'SwitchName',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'SwitchPortName',
      title: 'SwitchPortName',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'NicVendorName',
      title: 'NicVendorName',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'RegisterId',
      title: 'RegisterId',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'RegisterName',
      title: 'RegisterName',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'AdconnectionedUsers',
      title: 'AdconnectionedUsers',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'HotfixSyncTime',
      title: 'HotfixSyncTime',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'HotfixLastCheckTime',
      title: 'HotfixLastCheckTime',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'PendingHotfixCount',
      title: 'PendingHotfixCount',
      width: '120px',
      headerCell: 'myHeader'
    },
    {
      field: 'Email',
      title: 'Email',
      width: '120px',
      locked: true,
      headerCell: 'myHeader'
    },
    {
      field: 'Txt01',
      title: 'Txt01',
      width: '120px',
      locked: true,
      headerCell: 'myHeader'
    }
  ])
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return orderBy(dataItemsWithPage, sort.value)
  })
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  const createRandomData = count => {
    const productNames = [
      'Chai',
      'Chang',
      'Syrup',
      'Apple',
      'Orange',
      'Banana',
      'Lemon',
      'Pineapple',
      'Tea',
      'Milk'
    ]
    const unitPrices = [12.5, 10.1, 5.3, 7, 22.53, 16.22, 20, 50, 100, 120]
    const onlineStatus = ['online', 'offline']

    return Array(count)
      .fill({})
      .map(() => ({
        IsOnline: onlineStatus[Math.floor(Math.random() * onlineStatus.length)],
        Ipv4: productNames[Math.floor(Math.random() * productNames.length)],
        MAC: unitPrices[Math.floor(Math.random() * unitPrices.length)],
        Site: productNames[Math.floor(Math.random() * productNames.length)],
        NetworkName:
          productNames[Math.floor(Math.random() * productNames.length)],
        HostLastOnlineTime:
          productNames[Math.floor(Math.random() * productNames.length)],
        HostLastOfflineTime:
          productNames[Math.floor(Math.random() * productNames.length)],
        HostName: productNames[Math.floor(Math.random() * productNames.length)],
        HostWorkgroup:
          productNames[Math.floor(Math.random() * productNames.length)],
        OSDetail: productNames[Math.floor(Math.random() * productNames.length)],
        Status: productNames[Math.floor(Math.random() * productNames.length)],
        Ipv6Aggregate:
          productNames[Math.floor(Math.random() * productNames.length)],
        Duid: productNames[Math.floor(Math.random() * productNames.length)],
        Iaid: productNames[Math.floor(Math.random() * productNames.length)],
        LinkLocalIpAddress:
          productNames[Math.floor(Math.random() * productNames.length)],
        VlanID: productNames[Math.floor(Math.random() * productNames.length)],
        SwitchName:
          productNames[Math.floor(Math.random() * productNames.length)],
        SwitchPortName:
          productNames[Math.floor(Math.random() * productNames.length)],
        NicVendorName:
          productNames[Math.floor(Math.random() * productNames.length)],
        RegisterId:
          productNames[Math.floor(Math.random() * productNames.length)],
        RegisterName:
          productNames[Math.floor(Math.random() * productNames.length)],
        AdconnectionedUsers:
          productNames[Math.floor(Math.random() * productNames.length)],
        HotfixSyncTime:
          productNames[Math.floor(Math.random() * productNames.length)],
        HotfixLastCheckTime:
          productNames[Math.floor(Math.random() * productNames.length)],
        PendingHotfixCount:
          productNames[Math.floor(Math.random() * productNames.length)],
        Email: productNames[Math.floor(Math.random() * productNames.length)],
        Txt01: productNames[Math.floor(Math.random() * productNames.length)]
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  const columnReorder = options => {
    columns.value = options.columns
  }

  const sortChangeHandler = e => {
    sort.value = e.sort
  }

  const exportExcel = () => {
    saveExcel({
      data: dataItems.value,
      fileName: 'myFile',
      columns: [
        { field: 'IsOnline', title: 'IsOnline' },
        { field: 'Ipv4', title: 'Ipv4' }
      ]
    })
  }

  const restoreColumns = () => {
    columns.value.map(column => {
      if (column.hidden) {
        column.hidden = false
      }
    })
  }

  const hidecolumn = field => {
    columns.value.map(column => {
      if (column.field === field) {
        column.hidden = true
      }
    })
  }

  return {
    dataItems,
    skip,
    take,
    columns,
    result,
    total,
    sort,
    createRandomData,
    pageChangeHandler,
    columnReorder,
    sortChangeHandler,
    exportExcel,
    restoreColumns,
    hidecolumn
  }
}

export default useGrid
