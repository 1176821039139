import { ref, onMounted, onUnmounted } from 'vue'

const useMousePosition = () => {
  const x = ref(0)
  const y = ref(0)

  function handler(e) {
    x.value = e.clientX
    y.value = e.clientY
  }

  onMounted(() => {
    window.addEventListener('mousemove', handler, { passive: true })
  })

  onUnmounted(() => {
    window.removeEventListener('mousemove', handler, { passive: true })
  })

  return {
    x,
    y
  }
}

export default useMousePosition
