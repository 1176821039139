<template>
  <div class="app-wrapper" :class="{ 'hide-sidebar': isSidebarHide }">
    <Sidebar
      class="sidebar-container"
      @toggleSidebar="toggleSidebar"
      @showSidebar="showSidebar"
      :isSidebarHide="isSidebarHide"
    />
    <div class="main-container">
      <Navbar />
      <AppMain />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Sidebar from './components/Sidebar/index.vue'
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'

export default defineComponent({
  components: {
    Sidebar,
    Navbar,
    AppMain
  },
  setup() {
    const isSidebarHide = ref(false)

    const toggleSidebar = () => (isSidebarHide.value = !isSidebarHide.value)
    const showSidebar = () => (isSidebarHide.value = false)

    return {
      isSidebarHide,
      toggleSidebar,
      showSidebar
    }
  }
})
</script>

<style lang="scss" scoped></style>
