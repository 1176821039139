<template>
  <div>
    <h6>GoJS</h6>
    <HelloGoJs />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HelloGoJs from '../components/HelloGoJs.vue'

export default defineComponent({
  components: {
    HelloGoJs
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
