import { onMounted, onUnmounted, ref, onBeforeMount } from 'vue'
import { throttle } from '../utils/index'

const useWindowScrollPosition = () => {
  const x = ref(0)
  const y = ref(0)

  const setScrollPos = () => {
    x.value = window.pageXOffset
    y.value = window.pageYOffset
  }

  const onScroll = throttle(setScrollPos, 100)

  onBeforeMount(() => {
    setScrollPos()
  })

  onMounted(() => {
    window.addEventListener('scroll', onScroll, { passive: true })
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', onScroll)
  })

  return {
    x,
    y
  }
}

export default useWindowScrollPosition
