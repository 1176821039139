<template>
  <div
    class="p-tooltip"
    :style="{
      top: `${y + offsetY}px`,
      left: `${x + offsetX}px`
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useMousePosition from '@/composable/useMousePosition'

export default defineComponent({
  props: {
    show: Boolean,
    offsetX: {
      type: Number,
      default: 0
    },
    offsetY: {
      type: Number,
      default: 0
    }
  },
  setup() {
    // 支援鼠標位置
    const { x, y } = useMousePosition()
    return {
      x,
      y
    }
  }
})
</script>

<style scoped></style>
