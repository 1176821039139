
import { defineComponent, computed } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import UserDropdown from '@/components/Dropdown/UserDropdown.vue'
import EventDropdown from '@/components/Dropdown/EventDropdown.vue'
import useWindowScrollPosition from '@/composable/useWindowScrollPosition'
import BlockLayer from './BlockLayer.vue'
import useDragBlock from '@/composable/useDragBlock'

export default defineComponent({
  components: {
    Breadcrumb,
    UserDropdown,
    EventDropdown,
    BlockLayer
  },
  setup() {
    const { y } = useWindowScrollPosition()
    const showBg = computed(() => y.value > 46)

    // 注意：中控儀表板拖曳啟用時，顯示 block-layer
    const { showBlock } = useDragBlock()

    return {
      showBg,
      showBlock
    }
  }
})
