<template>
  <div>
    <h6>
      目前功能：paging, horizontal scrolling, vertical scrolling, locked column,
      reordering column, sort, export excel, hide/show column, cell render
    </h6>
    <h6>
      待加功能：filter, export csv
    </h6>
    <HelloKendoGrid />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HelloKendoGrid from '../components/HelloKendoGrid.vue'

export default defineComponent({
  components: {
    HelloKendoGrid
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
