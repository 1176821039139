
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { defineComponent, computed, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { Tooltip },
  props: {
    title: String,
    items: Array,
    isSidebarHide: Boolean
  },
  emits: ['showSidebar'],
  setup(props) {
    const route = useRoute()
    const hasActiveChildWhenSidebarHide = computed(() => {
      if (props.isSidebarHide) {
        return props.items?.reduce((acc, item: any) => {
          return item.pathName === route.name ? true : acc
        }, false)
      }
      return false
    })

    // tooltip 相關
    const isHover = ref(false)
    const showTooltip = computed(() => {
      if (props.isSidebarHide && isHover.value) {
        return true
      }
      return false
    })

    return {
      hasActiveChildWhenSidebarHide,
      showTooltip,
      isHover
    }
  }
})
