<template>
  <svg class="icon" :class="{ 'icon-spin': spin }">
    <!-- 注意：這裡加了 fixed-color 前綴，以免跟 SvgIcon 同 id 名 -->
    <use :xlink:href="`#fixed-color-${icon}`" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    spin: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style>
svg.icon {
  fill: currentColor;
  height: 20px;
  margin-bottom: 0.18em;
  vertical-align: middle;
  width: 20px;
}
svg.icon-spin {
  animation: icon-spin 2s infinite linear;
}
@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
