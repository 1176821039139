import { createI18n } from 'vue-i18n'
import en from './en'
import tw from './tw'

const messages = {
  en: en,
  tw: tw
}

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Compostion API
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: messages
})

export default i18n
