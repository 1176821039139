import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

/* Layout */
import Layout from '../layout/index.vue'

import KendoGrid from '../views/KendoGrid.vue'
import TreeView from '../views/TreeView.vue'
import GoJs from '../views/GoJs.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/switch-dashboard',
        name: 'SwitchDashboard',
        component: () =>
          import(
            /* webpackChunkName: "SwitchDashboard" */ '../views/SwitchDashboard/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '儀表板', pathName: '' },
            { text: '網路設備總覽', pathName: 'SwitchDashboard', active: true }
          ]
        }
      },
      {
        path: '/network-infrastructure',
        name: 'NetworkInfrastructure',
        component: () =>
          import(
            /* webpackChunkName: "NetworkInfrastructure" */ '../views/NetworkInfrastructure/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '儀表板', pathName: '' },
            {
              text: '系統架構圖',
              pathName: 'NetworkInfrastructure',
              active: true
            }
          ]
        }
      },
      {
        path: '/host',
        name: 'Host',
        component: () =>
          import(/* webpackChunkName: "Host" */ '../views/Host/index.vue'),
        meta: {
          breadcrumb: [{ text: '主機列表', pathName: 'Host', active: true }]
        }
      },
      {
        path: '/switch',
        name: 'Switch',
        component: () =>
          import(/* webpackChunkName: "Switch" */ '../views/Switch/index.vue'),
        meta: {
          breadcrumb: [{ text: 'Switch', pathName: 'Switch', active: true }]
        }
      },
      {
        path: '/topology',
        name: 'Topology',
        component: () =>
          import(
            /* webpackChunkName: "Topology" */ '../views/Topology/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '拓樸圖', pathName: 'Topology', active: true },
            { text: '區域 []', pathName: '' }
          ]
        }
      },
      {
        path: '/import',
        name: 'Import',
        component: () =>
          import(/* webpackChunkName: "Import" */ '../views/Import/index.vue'),
        meta: {
          breadcrumb: [{ text: '匯入', pathName: 'Import', active: true }]
        }
      },
      {
        path: '/customize-report-tree',
        name: 'CustomizeReportTree',
        component: () =>
          import(
            /* webpackChunkName: "CustomizeReportTree" */ '../views/CustomizeReportTree/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '報表', pathName: 'CustomizeReportTree', active: true }
          ]
        }
      },
      {
        path: '/dhcp',
        name: 'Dhcp',
        component: () =>
          import(/* webpackChunkName: "Dhcp" */ '../views/Dhcp/index.vue'),
        meta: {
          breadcrumb: [
            { text: 'DHCP 租期列表', pathName: 'Dhcp', active: true }
          ]
        }
      },
      {
        path: '/custom-fields',
        name: 'CustomFields',
        component: () =>
          import(
            /* webpackChunkName: "CustomFields" */ '../views/CustomFields/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '擴充欄位列表', pathName: 'CustomFields', active: true }
          ]
        }
      },
      // 設定
      {
        // 建置環境
        path: '/build-env',
        name: 'BuildEnv',
        redirect: '/build-env/site',
        component: () =>
          import(
            /* webpackChunkName: "BuildEnv" */ '../views/Setting/BuildEnv/index.vue'
          ),
        children: [
          {
            // 區域列表
            path: 'site',
            name: 'Site',
            component: () =>
              import(
                /* webpackChunkName: "Site" */ '../views/Setting/BuildEnv/Site/index.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: true }
              ]
            }
          },
          {
            // 區域設定/屬性設定/:id
            path: 'site/edit-general/:id',
            name: 'EditGeneral',
            component: () =>
              import(
                /* webpackChunkName: "EditGeneral" */ '../views/Setting/BuildEnv/Site/EditGeneral.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: false },
                { text: '區域設定[Taipei Office]', pathName: '' },
                { text: '屬性設定', pathName: 'EditGeneral', active: true }
              ]
            }
          },
          {
            // 區域設定/網段群組設定/:id
            path: 'site/group-setting/:id',
            name: 'GroupSetting',
            component: () =>
              import(
                /* webpackChunkName: "GroupSetting" */ '../views/Setting/BuildEnv/Site/GroupSetting.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: false },
                { text: '區域設定[Taipei Office]', pathName: '' },
                { text: '網段群組設定', pathName: 'GroupSetting', active: true }
              ]
            }
          },
          {
            // 區域設定/註冊的 IP 派發來源/:id
            path: 'site/dhcp-list/:id',
            name: 'DHCPList',
            component: () =>
              import(
                /* webpackChunkName: "DHCPList" */ '../views/Setting/BuildEnv/Site/DHCPList.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: false },
                { text: '區域設定[Taipei Office]', pathName: '' },
                {
                  text: '註冊的 IP 派發來源',
                  pathName: 'DHCPList',
                  active: true
                }
              ]
            }
          },
          {
            // 區域設定/封鎖訊息 & 驗證方式/:id
            path: 'site/edit-block-message/:id',
            name: 'EditBlockMessage',
            component: () =>
              import(
                /* webpackChunkName: "EditBlockMessage" */ '../views/Setting/BuildEnv/Site/EditBlockMessage.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: false },
                { text: '區域設定[Taipei Office]', pathName: '' },
                {
                  text: '封鎖訊息 & 驗證方式',
                  pathName: 'EditBlockMessage',
                  active: true
                }
              ]
            }
          },
          {
            // 區域設定/AD 自動授權設定/:id
            path: 'site/ad-auto-register-setting/:id',
            name: 'ADAutoRegisterSetting',
            component: () =>
              import(
                /* webpackChunkName: "ADAutoRegisterSetting" */ '../views/Setting/BuildEnv/Site/ADAutoRegisterSetting.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: false },
                { text: '區域設定[Taipei Office]', pathName: '' },
                {
                  text: 'AD 自動授權設定',
                  pathName: 'ADAutoRegisterSetting',
                  active: true
                }
              ]
            }
          },
          {
            // 區域設定/802.1x 設定/:id
            path: 'site/radius-site-config-setting/:id',
            name: 'RadiusSiteConfigSetting',
            component: () =>
              import(
                /* webpackChunkName: "RadiusSiteConfigSetting" */ '../views/Setting/BuildEnv/Site/RadiusSiteConfigSetting.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '區域列表', pathName: 'Site', active: false },
                { text: '區域設定[Taipei Office]', pathName: '' },
                {
                  text: '802.1x 設定',
                  pathName: 'RadiusSiteConfigSetting',
                  active: true
                }
              ]
            }
          },
          {
            // 網段列表
            path: 'network',
            name: 'Network',
            component: () =>
              import(
                /* webpackChunkName: "Network" */ '../views/Setting/BuildEnv/Network/index.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '網段列表', pathName: 'Network', active: true }
              ]
            }
          },
          {
            // 網段設定/管理範圍設定/:id
            path: 'network/range-setting/:id',
            name: 'RangeSetting',
            component: () =>
              import(
                /* webpackChunkName: "RangeSetting" */ '../views/Setting/BuildEnv/Network/RangeSetting.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '網段列表', pathName: 'Network', active: false },
                { text: '網段設定[業務課]', pathName: '' },
                {
                  text: '管理範圍設定',
                  pathName: 'RangeSetting',
                  active: true
                }
              ]
            }
          },
          {
            // 網段設定/屬性設定/:id
            path: 'network/general-setting/:id',
            name: 'GeneralSetting',
            component: () =>
              import(
                /* webpackChunkName: "GeneralSetting" */ '../views/Setting/BuildEnv/Network/GeneralSetting.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '網段列表', pathName: 'Network', active: false },
                { text: '網段設定[業務課]', pathName: '' },
                {
                  text: '屬性設定',
                  pathName: 'GeneralSetting',
                  active: true
                }
              ]
            }
          },
          {
            // 網段設定/DHCP DNS 設定/:id
            path: 'network/dhcp-dns-setting/:id',
            name: 'DHCPDNSSetting',
            component: () =>
              import(
                /* webpackChunkName: "DHCPDNSSetting" */ '../views/Setting/BuildEnv/Network/DHCPDNSSetting.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: '網段列表', pathName: 'Network', active: false },
                { text: '網段設定[業務課]', pathName: '' },
                {
                  text: 'DHCP DNS 設定',
                  pathName: 'DHCPDNSSetting',
                  active: true
                }
              ]
            }
          },
          {
            // Probe 列表
            path: 'probe',
            name: 'Probe',
            component: () =>
              import(
                /* webpackChunkName: "Probe" */ '../views/Setting/BuildEnv/Probe/index.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '建置環境', pathName: 'BuildEnv', active: true },
                { text: 'Probe 列表', pathName: 'Probe', active: true }
              ]
            }
          }
        ]
      },
      {
        // 符規標準
        path: '/pre-check',
        name: 'PreCheck',
        redirect: '/pre-check/pre-check-list',
        component: () =>
          import(
            /* webpackChunkName: "PreCheck" */ '../views/Setting/PreCheck/index.vue'
          ),
        children: [
          {
            // 符規標準列表
            path: 'pre-check-list',
            name: 'PreCheckList',
            component: () =>
              import(
                /* webpackChunkName: "PreCheckList" */ '../views/Setting/PreCheck/PreCheckList/index.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '符規標準', pathName: 'PreCheck', active: true },
                { text: '符規標準列表', pathName: 'PreCheckList', active: true }
              ]
            }
          },
          {
            // 未符規設備列表
            path: 'against-rules',
            name: 'AgainstRules',
            component: () =>
              import(
                /* webpackChunkName: "AgainstRules" */ '../views/Setting/PreCheck/AgainstRules/index.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '符規標準', pathName: 'PreCheck', active: true },
                {
                  text: '未符規設備列表',
                  pathName: 'AgainstRules',
                  active: true
                }
              ]
            }
          },
          {
            // 更新檔來源
            path: 'path-source',
            name: 'PathSource',
            component: () =>
              import(
                /* webpackChunkName: "PathSource" */ '../views/Setting/PreCheck/PathSource/index.vue'
              ),
            meta: {
              breadcrumb: [
                { text: '設定', pathName: '' },
                { text: '符規標準', pathName: 'PreCheck', active: true },
                { text: '更新檔來源', pathName: 'PathSource', active: true }
              ]
            }
          }
        ]
      }
      // 設定結束
    ]
  },
  // 儀表板
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Layout,
    redirect: '/',
    children: [
      {
        path: '', // 自己
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ '../views/Dashboard/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '儀表板', pathName: '' },
            { text: '中控儀表板', pathName: 'Dashboard', active: true }
          ]
        }
      },
      {
        path: 'ip-conflict',
        name: 'IpConflict',
        component: () =>
          import(
            /* webpackChunkName: "IpConflict" */ '../views/IpConflict/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '儀表板', pathName: '' },
            { text: '中控儀表板', pathName: 'Dashboard', active: false },
            {
              text: 'IP 衝突開始',
              pathName: 'IpConflict',
              active: true
            }
          ]
        }
      },
      {
        path: 'ipv4-broadcast',
        name: 'Ipv4Broadcast',
        component: () =>
          import(
            /* webpackChunkName: "Ipv4Broadcast" */ '../views/Ipv4Broadcast/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '儀表板', pathName: '' },
            { text: '中控儀表板', pathName: 'Dashboard', active: false },
            {
              text: 'IPv4 廣播超量',
              pathName: 'Ipv4Broadcast',
              active: true
            }
          ]
        }
      }
    ]
  },
  // 事件
  {
    path: '/event-log',
    name: 'EventLog',
    component: Layout,
    children: [
      {
        path: 'audit',
        name: 'AuditLog',
        component: () => import('../views/EventLog/Audit/index.vue'),
        meta: {
          breadcrumb: [
            { text: '事件', pathName: '' },
            { text: '管理日誌', pathName: 'AuditEventLog', active: true }
          ]
        }
      },
      {
        path: 'general',
        name: 'GeneralEventLog',
        component: () =>
          import(
            /* webpackChunkName: "GeneralEventLog" */ '../views/EventLog/Audit/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '事件', pathName: '' },
            { text: '系統事件', pathName: 'GeneralEventLog', active: true }
          ]
        }
      },
      {
        path: 'exception',
        name: 'ExceptionEventLog',
        component: () =>
          import(
            /* webpackChunkName: "ExceptionEventLog" */ '../views/EventLog/Audit/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '事件', pathName: '' },
            {
              text: '異常事件',
              pathName: 'ExceptionEventLog',
              active: true
            }
          ]
        }
      },
      {
        path: 'host',
        name: 'HostEventLog',
        component: () =>
          import(
            /* webpackChunkName: "HostEventLog" */ '../views/EventLog/Audit/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '事件', pathName: '' },
            { text: '主機事件', pathName: 'HostEventLog', active: true }
          ]
        }
      },
      {
        path: 'authorization',
        name: 'AuthorizationEventLog',
        component: () =>
          import(
            /* webpackChunkName: "AuthorizationEventLog" */ '../views/EventLog/Audit/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '事件', pathName: '' },
            {
              text: '封鎖事件',
              pathName: 'AuthorizationEventLog',
              active: true
            }
          ]
        }
      },
      {
        path: 'empower',
        name: 'EmpowerEventLog',
        component: () =>
          import(
            /* webpackChunkName: "EmpowerEventLog" */ '../views/EventLog/Audit/index.vue'
          ),
        meta: {
          breadcrumb: [
            { text: '事件', pathName: '' },
            { text: '授權事件', pathName: 'EmpowerEventLog', active: true }
          ]
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login/index.vue')
  },
  {
    path: '/verification',
    name: 'Verification',
    component: () =>
      import(
        /* webpackChunkName: "Verification" */ '../views/Verification/index.vue'
      )
  },
  {
    path: '/verification/success',
    name: 'verifySuccessFully',
    component: Layout,
    children: [
      {
        path: '',
        name: 'verifySuccessFully',
        component: () => import('../views/Verification/success.vue'),
        meta: {
          breadcrumb: [{ text: '授權成功', pathName: '' }]
        }
      }
    ]
  },
  {
    path: '/grid',
    name: 'KendoGrid',
    component: KendoGrid
  },
  {
    path: '/tree-view',
    name: 'TreeView',
    component: TreeView
  },
  {
    path: '/gojs',
    name: 'GoJs',
    component: GoJs
  },
  {
    path: '/echart',
    name: 'Echart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "echart" */ '../views/Echart.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
