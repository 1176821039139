
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import Tooltip from '@/components/Tooltip/Tooltip.vue'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isSidebarHide: Boolean
  },
  emits: ['toggleSubmenu', 'showSidebar'],
  setup(props, ctx) {
    const route = useRoute()
    const subMenuElement = ref(null) as any

    const hasChildren = computed(() => props.item.childrens.length > 0)

    const hasActiveChildWhenUnExpand = computed(() => {
      // 有子選單，且閉合時
      if (hasChildren.value && !props.item.isExpand) {
        // 取得目前所有匹配路由名稱
        const matchedName = route.matched.map(item => item.name)
        console.log('目前所有匹配路由名稱:', matchedName)
        // 任ㄧ子項目，為目前所有匹配路由的其中一個
        return props.item.childrens.reduce((acc, child) => {
          return matchedName.includes(child.pathName) ? true : acc
        }, false)
      }
      return false
    })

    const handleClick = () => {
      if (props.isSidebarHide) {
        // Change isSidebarHide to false
        ctx.emit('showSidebar', props.item.title)
      }
      // toggle item.isExpand
      ctx.emit('toggleSubmenu', props.item.title)
    }

    const setExpandHeight = () => {
      // subMenuElement 有值 (onMounted), 且含子選單時, 設定選單高度
      if (subMenuElement.value && hasChildren.value) {
        const expandHeight = `${props.item.childrens.length * 35}px`
        subMenuElement.value.style.maxHeight = props.item.isExpand
          ? expandHeight
          : '0px'
      }
    }
    // 響應式追蹤 item.isExpand 變化，動態設定選單高度
    const getItemIsExpand = computed(() => props.item.isExpand)
    watch(getItemIsExpand, () => {
      setExpandHeight()
    })

    onMounted(() => {
      // 初始檢查 isExpand items 高度
      setExpandHeight()
    })

    // tooltip 相關
    const isHover = ref(false)
    const showTooltip = computed(() => {
      if (props.isSidebarHide && isHover.value) {
        return true
      }
      return false
    })

    return {
      hasChildren,
      handleClick,
      subMenuElement,
      hasActiveChildWhenUnExpand,
      isHover,
      showTooltip
    }
  }
})
