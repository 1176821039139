<template>
  <div class="user-dropdown">
    <!-- 使用者選單：按鈕 -->
    <div
      class="user-dropdown-btn"
      @click="toggleDropdown"
      :class="{ 'is-open': isOpen }"
      ref="btn"
    >
      <span class="user-text">Tommy</span>
      <SvgIcon icon="arrow-down" />
    </div>
    <!-- 使用者選單：內容 -->
    <teleport to="#dropdown-container">
      <div
        class="p-dropdown-menu user-dropdown-menu"
        v-show="isOpen"
        ref="menu"
      >
        <router-link to="/">帳號管理</router-link>
        <router-link to="/">修改密碼</router-link>
        <router-link to="/">訂閱通知設定</router-link>
        <div class="divider"></div>
        <div class="theme-switch-wrapper">
          <span class="text">深色模式</span>
          <KendoSwitch
            class="theme-switch"
            :checked="isDarkMode"
            @change="toggleTheme"
          ></KendoSwitch>
        </div>
        <div class="divider"></div>
        <router-link to="/">登出</router-link>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import useDropdown from '@/composable/useDropdown'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    KendoSwitch
  },
  setup() {
    // dropdown 相關邏輯
    const { isOpen, toggleDropdown, btn, menu } = useDropdown()

    // darkmode 相關
    const store = useStore()
    const router = useRouter()
    const isDarkMode = computed(() => store.state.theme.isDarkMode)
    const toggleTheme = () => {
      store.commit('theme/TOGGLE_THEME')
      router.go(0)
    }

    return {
      isOpen,
      toggleDropdown,
      btn,
      menu,
      isDarkMode,
      toggleTheme
    }
  }
})
</script>

<style scoped></style>
