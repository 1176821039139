
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.data'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'

export default defineComponent({
  components: {
    TreeView,
    HierarchicalDataSource
  },
  data: function() {
    return {
      template:
        '# if (item.isParent) { #' +
        `<span class='k-icon k-i-link-horizontal'></span>
          <span class='k-icon k-i-cloud'></span>` +
        '# } #' +
        '# if (!item.isParent) { #' +
        "<span class='k-icon k-i-user'></span>" +
        '# } #' +
        '#: item.name #',
      dsModel: {
        id: 'id',
        hasChildren: 'isParent',
        children: {
          type: 'jsonp',
          transport: {
            read: {
              url: 'http://localhost:8088/getSwitchByVendor'
            }
          },
          schema: {
            model: {
              id: 'id',
              hasChildren: 'isParent',
              children: {
                type: 'jsonp',
                transport: {
                  read: {
                    url: 'http://localhost:8088/getPorts'
                  }
                },
                schema: {
                  model: {
                    id: 'id',
                    hasChildren: false
                  }
                }
              }
            }
          }
        }
      }
    }
  }
})
