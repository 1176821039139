import { onMounted, ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'

const useBreadcrumb = () => {
  const route = useRoute()
  const breadcrumbList = ref([])
  const getBreadcrumbList = () => {
    // 取得 route meta 的 breadcrumb 參數
    if (route.meta.breadcrumb) {
      breadcrumbList.value = [...route.meta.breadcrumb]
    }
  }

  const fullPath = computed(() => route.fullPath)
  watch(fullPath, () => {
    getBreadcrumbList()
  })

  onMounted(() => {
    getBreadcrumbList()
  })

  return {
    breadcrumbList
  }
}

export default useBreadcrumb
