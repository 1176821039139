
import { defineComponent } from 'vue'
import AppLoader from '@/components/Loader/AppLoader.vue'

export default defineComponent({
  components: {
    AppLoader
  },
  setup() {
    return {}
  }
})
