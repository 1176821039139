
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import Logo from './Logo.vue'
import SidebarMenu from './SidebarMenu.vue'
import SidebarMenuItem from './SidebarMenuItem.vue'
import CustomMenu from './CustomMenu.vue'
import BlockLayer from '../BlockLayer.vue'
import useDragBlock from '@/composable/useDragBlock'

export default defineComponent({
  components: {
    Logo,
    SidebarMenu,
    SidebarMenuItem,
    CustomMenu,
    BlockLayer
  },
  props: {
    isSidebarHide: Boolean
  },
  emits: ['toggleSidebar', 'showSidebar'],
  setup(props, ctx) {
    const customMenuItems = reactive([
      { title: 'DHCP 租期列表', pathName: 'Dhcp' },
      { title: '主機事件', pathName: 'HostEventLog' },
      { title: '擴充欄位列表', pathName: 'CustomFields' }
    ])
    const menuItems = reactive([
      {
        title: '儀表板',
        icon: 'dashboard',
        isExpand: true,
        pathName: '',
        childrens: [
          {
            title: '中控儀表板',
            pathName: 'Dashboard'
          },
          {
            title: '網路設備總覽',
            pathName: 'SwitchDashboard'
          },
          {
            title: '系統架構圖',
            pathName: 'NetworkInfrastructure'
          }
        ]
      },
      {
        title: '主機列表',
        icon: 'host',
        isExpand: false,
        pathName: 'Host',
        childrens: []
      },
      {
        title: 'Switch',
        icon: 'switch',
        isExpand: false,
        pathName: 'Switch',
        childrens: []
      },
      {
        title: '拓樸圖',
        icon: 'topology',
        isExpand: false,
        pathName: 'Topology',
        childrens: []
      },
      {
        title: '事件',
        icon: 'event',
        isExpand: false,
        pathName: '',
        childrens: [
          {
            title: '管理日誌',
            pathName: 'AuditLog'
          },
          {
            title: '系統事件',
            pathName: 'GeneralEventLog'
          },
          {
            title: '異常事件',
            pathName: 'ExceptionEventLog'
          },
          {
            title: '主機事件',
            pathName: 'HostEventLog'
          },
          {
            title: '封鎖事件',
            pathName: 'AuthorizationEventLog'
          },
          {
            title: '授權事件',
            pathName: 'EmpowerEventLog'
          }
        ]
      },
      {
        title: '匯入',
        icon: 'import',
        isExpand: false,
        pathName: 'Import',
        childrens: []
      },
      {
        title: '報表',
        icon: 'report',
        isExpand: false,
        pathName: 'CustomizeReportTree',
        childrens: []
      },
      {
        title: '設定',
        icon: 'setup',
        isExpand: false,
        pathName: '',
        childrens: [
          {
            title: '建置環境',
            pathName: 'BuildEnv'
          },
          {
            title: '符規標準',
            pathName: 'PreCheck'
          }
        ]
      }
    ])

    // 暫存隱藏選單前的 expand items
    const expandItemsWhenSidebarHide = ref([]) as any

    // watch 接受 getter/effect function, ref, reactive object, or an array of these types
    // 因此重新 get props.isSidebarHide
    const getIsSidebarHide = computed(() => props.isSidebarHide)
    watch(getIsSidebarHide, isSidebarHide => {
      if (isSidebarHide) {
        //sidebar 隱藏時，關閉所有的 submenu
        const tempItems: Array<string> = []
        menuItems.map(item => {
          if (item.isExpand) {
            item.isExpand = false
            tempItems.push(item.title)
          }
          return item
        })
        // 紀錄關閉的項目
        expandItemsWhenSidebarHide.value = tempItems
      } else {
        //sidebar 打開時，重新展開先前關閉的 submenu
        setTimeout(() => {
          menuItems.map(item => {
            if (expandItemsWhenSidebarHide.value.includes(item.title)) {
              item.isExpand = true
            }
            return item
          })
          expandItemsWhenSidebarHide.value = []
        }, 200)
      }
    })

    const toggleSubmenu = title => {
      menuItems.map(item => {
        if (item.title === title) item.isExpand = !item.isExpand
        return item
      })
    }
    const showSidebar = title => {
      ctx.emit('showSidebar', title)
    }

    // 注意：中控儀表板拖曳啟用時，顯示 block-layer
    const { showBlock } = useDragBlock()

    return {
      customMenuItems,
      menuItems,
      toggleSubmenu,
      showSidebar,
      showBlock
    }
  }
})
