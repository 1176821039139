import Cookies from 'js-cookie'

const getTheme = () => {
  // 檢查 cookies
  if (Cookies.get('darkmode') === undefined) {
    Cookies.set('darkmode', 'TRUE')
    return true
  } else if (Cookies.get('darkmode') === 'TRUE') {
    return true
  } else {
    return false
  }
}

console.log('isDarkMode:', getTheme())

const state = {
  isDarkMode: getTheme()
}

const mutations = {
  TOGGLE_THEME: () => {
    // 變更 cookie
    if (Cookies.get('darkmode') === 'TRUE') {
      Cookies.set('darkmode', 'FALSE')
    } else {
      Cookies.set('darkmode', 'TRUE')
    }
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
