
import { defineComponent, onMounted } from 'vue'
import useGrid from '../composable/useGrid.js'
import { Grid } from '@progress/kendo-vue-grid'
import gridHeader from '../components/gridHeader.vue'

export default defineComponent({
  components: {
    Grid,
    gridHeader
  },
  setup() {
    const {
      columns,
      skip,
      take,
      dataItems,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      columnReorder,
      sortChangeHandler,
      exportExcel,
      restoreColumns,
      hidecolumn
    } = useGrid()

    onMounted(() => {
      dataItems.value = createRandomData(65536) as never[]
    })

    return {
      columns,
      skip,
      take,
      dataItems,
      result,
      total,
      sort,
      pageChangeHandler,
      columnReorder,
      sortChangeHandler,
      exportExcel,
      restoreColumns,
      hidecolumn
    }
  }
})
