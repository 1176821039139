import { computed } from 'vue'
import { useStore } from 'vuex'

const useTheme = () => {
  const store = useStore()
  const isDarkMode = computed(() => store.state.theme.isDarkMode)

  const loadDarkModeCss = () =>
    import(
      /* webpackChunkName: "dark-theme" */ '../assets/styles/theme-dark.scss'
    )
  const loadLightModeCss = () =>
    import(
      /* webpackChunkName: "light-theme" */ '../assets/styles/theme-light.scss'
    )

  const initTheme = () => {
    console.log('loadThemeCss...')
    if (isDarkMode.value) {
      loadDarkModeCss()
    } else {
      loadLightModeCss()
    }
  }

  initTheme()

  return {
    isDarkMode
  }
}

export default useTheme
