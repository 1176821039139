const state = {
  list: [
    { order: 1, componentName: 'NetworkStatusPanel' },
    { order: 2, componentName: 'SystemStatusPenel' },
    { order: 3, componentName: 'DayEventsPanel' },
    { order: 4, componentName: 'Ipv4StackingPanel' },
    { order: 5, componentName: 'Ipv6StackingPanel' },
    { order: 6, componentName: 'AuditLogPanel' },
    { order: 7, componentName: 'ExceptionEventsPanel' }
  ],
  isDargEnable: false
}

const mutations = {
  ENABLE_DRAG: state => {
    state.isDargEnable = true
  },
  DISABLE_DRAG: state => {
    state.isDargEnable = false
  },
  UPDATE_LIST: (state, newList) => {
    state.list = newList
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
