
import { defineComponent, reactive } from 'vue'
import EventDropdownItem from '@/components/Dropdown/EventDropdownItem.vue'
import useDropdown from '@/composable/useDropdown'

export default defineComponent({
  components: {
    EventDropdownItem
  },
  setup() {
    // dropdown 相關邏輯
    const { isOpen, toggleDropdown, btn, menu } = useDropdown(13, 0)

    // FIXME: 待加入 fetch events api
    // FIXME: 待加入 '未讀通知'判斷邏輯 (.event-dropdown.has-unread 可以切換小紅點)
    // FIXME: 待加入 '全部標示為已讀'功能

    // events
    const events = reactive([
      {
        EventID: 0,
        HappenedTime: '2021/01/14 15:51:20',
        Name: '[修改 - 變更連接埠型態]',
        Detail: '3CGS / Gi0/1 / 對外埠 > 一般埠 3CGS / Gi0/1 / 對外埠 > 一般埠',
        IP: 'IP: 101.111.111.2',
        MAC: 'MAC: 0050568827B6',
        Read: true,
        Icon: 'switch'
      },
      {
        EventID: 1,
        HappenedTime: '2021/01/14 15:51:20',
        Name: '[修改 - 變更連接埠型態]',
        Detail: '3CGS / Gi0/1 / 對外埠 > 一般埠 3CGS / Gi0/1 / 對外埠 > 一般埠',
        IP: 'IP: 101.111.111.2',
        MAC: 'MAC: 0050568827B6',
        Read: false,
        Icon: 'switch'
      },
      {
        EventID: 2,
        HappenedTime: '2021/01/14 15:51:20',
        Name: '[修改 - 變更連接埠型態]',
        Detail: '3CGS / Gi0/1 / 對外埠 > 一般埠 3CGS / Gi0/1 / 對外埠 > 一般埠',
        IP: 'IP: 101.111.111.2',
        MAC: 'MAC: 0050568827B6',
        Read: true,
        Icon: 'switch'
      },
      {
        EventID: 3,
        HappenedTime: '2021/01/14 15:51:20',
        Name: '[修改 - 變更連接埠型態]',
        Detail: '3CGS / Gi0/1 / 對外埠 > 一般埠 3CGS / Gi0/1 / 對外埠 > 一般埠',
        IP: 'IP: 101.111.111.2',
        MAC: 'MAC: 0050568827B6',
        Read: false,
        Icon: 'switch'
      },
      {
        EventID: 4,
        HappenedTime: '2021/01/14 15:51:20',
        Name: '[修改 - 變更連接埠型態]',
        Detail: '3CGS / Gi0/1 / 對外埠 > 一般埠 3CGS / Gi0/1 / 對外埠 > 一般埠',
        IP: 'IP: 101.111.111.2',
        MAC: 'MAC: 0050568827B6',
        Read: false,
        Icon: 'switch'
      },
      {
        EventID: 5,
        HappenedTime: '2021/01/14 15:51:20',
        Name: '[修改 - 變更連接埠型態]',
        Detail: '3CGS / Gi0/1 / 對外埠 > 一般埠 3CGS / Gi0/1 / 對外埠 > 一般埠',
        IP: 'IP: 101.111.111.2',
        MAC: 'MAC: 0050568827B6',
        Read: false,
        Icon: 'switch'
      }
    ])

    return {
      isOpen,
      toggleDropdown,
      btn,
      menu,
      events
    }
  }
})
