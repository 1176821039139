<template>
  <!-- 可變換顏色的 icon svg sprite, 限定單色 -->
  <SvgSprite />
  <!-- 不可變換顏色的 icon svg sprite, 多色 -->
  <SvgSpriteFixedColor />
  <router-view />
  <KendoNotification
    ref="popupNotification"
    :position-top="50"
    :position-right="30"
    :hide-on-click="true"
    :auto-hide-after="1100"
    :height="54"
  />
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, ref, onMounted } from 'vue'
import SvgSprite from './components/icon/SvgSprite.vue'
import SvgSpriteFixedColor from './components/icon/SvgSpriteFixedColor.vue'
import useTheme from './composable/useTheme'
import '@progress/kendo-ui/js/kendo.notification'
import { Notification } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    SvgSprite,
    SvgSpriteFixedColor,
    KendoNotification: Notification
  },
  setup() {
    const { isDarkMode } = useTheme()

    // KendoNotification 元件，CHECK: (https://www.telerik.com/kendo-vue-ui/components/popups/notification/)
    const popupNotification = ref('')
    // 取得 Vue Instance, CHECK: (https://v3.vuejs.org/api/composition-api.html#getcurrentinstance)
    const vueInstance = getCurrentInstance()

    onMounted(() => {
      // 註冊全域 API，也就是 this.$toast
      //@ts-ignore
      vueInstance.appContext.config.globalProperties.$toast = popupNotification.value.kendoWidget()
    })

    return {
      isDarkMode,
      popupNotification
    }
  }
})
</script>

<style lang="scss"></style>
