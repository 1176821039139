<template>
  <div class="container">
    <div id="goDiagramDiv" ref="goDiagramDiv">
      goDiagramDiv
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import go from 'gojs'
import { networkInfrastructure } from '@/mock/data/networkInfrastructure'

export default defineComponent({
  setup() {
    const goDiagramDiv = ref('')
    const init = divRef => {
      const g$ = go.GraphObject.make // for conciseness in defining templates
      const myDiagram = g$(
        go.Diagram,
        divRef.value, // create a Diagram for the DIV HTML element
        {
          // enable undo & redo
          'undoManager.isEnabled': true
        }
      )

      const nodeDataArray = networkInfrastructure.nodeData
      const linkDataArray = networkInfrastructure.linkData

      myDiagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray)
      myDiagram.layout = new go.TreeLayout()
      myDiagram.nodeTemplate = g$(
        go.Node,
        'Auto',
        g$(
          go.Shape,
          'RoundedRectangle',
          { fill: 'white' },
          new go.Binding('fill', 'color')
        ),
        g$(go.TextBlock, 'text', { margin: 10 }, new go.Binding('text', 'name'))
      )
      // myDiagram.linkTemplate = g$(
      //   go.Link,
      //   g$(go.Shape, { strokeWidth: 2 }, new go.Binding('stroke', 'color')),
      //   g$(
      //     go.Shape,
      //     { toArrow: 'Standard', stroke: null },
      //     new go.Binding('fill', 'color')
      //   )
      // )
    }

    onMounted(() => {
      init(goDiagramDiv)
    })
    return {
      goDiagramDiv
    }
  }
})
</script>

<style scoped>
#goDiagramDiv {
  width: 100%;
  height: 800px;
}
</style>
