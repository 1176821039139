const state = {
  isLoading: false
}

const mutations = {
  SET_LOADING: state => {
    state.isLoading = true
  },
  UNSET_LOADING: state => {
    state.isLoading = false
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
