export default {
  message: {
    hello: '你好',
    apple: '蘋果'
  },
  util: {
    selectDownloadType: '選擇下載檔案類型',
    pdfFile: 'pdf 檔案',
    excelFile: 'excel 檔案'
  }
}
