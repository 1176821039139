
import { computed, defineComponent } from 'vue'
import useDropdown from '@/composable/useDropdown'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    KendoSwitch
  },
  setup() {
    // dropdown 相關邏輯
    const { isOpen, toggleDropdown, btn, menu } = useDropdown()

    // darkmode 相關
    const store = useStore()
    const router = useRouter()
    const isDarkMode = computed(() => store.state.theme.isDarkMode)
    const toggleTheme = () => {
      store.commit('theme/TOGGLE_THEME')
      router.go(0)
    }

    return {
      isOpen,
      toggleDropdown,
      btn,
      menu,
      isDarkMode,
      toggleTheme
    }
  }
})
