const networkInfrastructure = {
  nodeData: [
    {
      key: 0,
      name: 'PIXIS DSI',
      type: 0,
      id: 0,
      loc: null,
      category: 'PIXISPDI',
      isTreeExpanded: true
    },
    {
      EnableOutOfRangeBlock: false,
      IsIpv4SafeModeEnabled: true,
      IsMacSafeModeEnabled: true,
      IsMonitorEnabled: false,
      type: 1,
      key: 1,
      name: 'Default Site',
      id: 1,
      loc: null,
      category: 'Site',
      isTreeExpanded: true
    },
    {
      EnableOutOfRangeBlock: false,
      IsIpv4SafeModeEnabled: false,
      IsMacSafeModeEnabled: true,
      IsMonitorEnabled: true,
      type: 1,
      key: 2,
      name: 'Taipei Office',
      id: 2,
      loc: null,
      category: 'Site',
      isTreeExpanded: true
    },
    {
      EnableOutOfRangeBlock: false,
      IsIpv4SafeModeEnabled: false,
      IsMacSafeModeEnabled: true,
      IsMonitorEnabled: true,
      type: 1,
      key: 3,
      name: 'San Jose Office',
      id: 3,
      loc: null,
      category: 'Site',
      isTreeExpanded: true
    },
    {
      EnableOutOfRangeBlock: false,
      IsIpv4SafeModeEnabled: false,
      IsMacSafeModeEnabled: true,
      IsMonitorEnabled: false,
      type: 1,
      key: 4,
      name: '資訊處 (14)',
      id: 17,
      loc: null,
      category: 'Site',
      isTreeExpanded: true
    },
    {
      EnableOutOfRangeBlock: false,
      IsIpv4SafeModeEnabled: false,
      IsMacSafeModeEnabled: false,
      IsMonitorEnabled: true,
      type: 1,
      key: 5,
      name: 'Brunch',
      id: 21,
      loc: null,
      category: 'Site',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: true,
      VlanId: '86',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 6,
      name: 'Accounting Department',
      id: 1,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: false,
      VlanId: '87',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 7,
      name: 'Service Department',
      id: 2,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: true,
      VlanId: '67',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 2,
      HasOutOfVlanHost: false,
      key: 8,
      name: 'Sales Department',
      id: 4,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: false,
      VlanId: '61',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 3,
      HasOutOfVlanHost: false,
      key: 9,
      name: 'R\u0026D Department',
      id: 6,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: false,
      IsDhcpEnabled: true,
      VlanId: '50',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 10,
      name: '資訊室',
      id: 11,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: true,
      VlanId: '10',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 11,
      name: 'Default',
      id: 15,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: false,
      VlanId: '12',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 12,
      name: 'Call Center',
      id: 16,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: true,
      VlanId: '1',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 13,
      name: 'VLAN1',
      id: 17,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: false,
      VlanId: '100',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 14,
      name: 'Hyderabad',
      id: 25,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: false,
      VlanId: '1233',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 15,
      name: 'test',
      id: 26,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: true,
      VlanId: '66',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: true,
      key: 16,
      name: '業務課',
      id: 27,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      IsMonitorEnabled: true,
      IsDhcpEnabled: true,
      VlanId: '41',
      HasUnknownDhcpServer: false,
      type: 2,
      ViolationType: 0,
      HasOutOfVlanHost: false,
      key: 17,
      name: 'DF-OA-A3F',
      id: 29,
      loc: null,
      category: 'Network',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: true,
      type: 3,
      NetworkId: 1,
      IpVersion: 1,
      StartIp: '192.168.86.1',
      EndIp: '192.168.86.254',
      Prefix: null,
      DhcpPool: [
        {
          IpRangeId: 8,
          StartIp: '192.168.86.1',
          EndIp: '192.168.86.100',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        },
        {
          IpRangeId: 8,
          StartIp: '192.168.86.230',
          EndIp: '192.168.86.235',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 18,
      name: '192.168.86.X',
      id: 8,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: true,
      type: 3,
      NetworkId: 1,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:b030:1228:1086::',
      DhcpPool: [
        {
          IpRangeId: 29,
          StartIp: '2001:b030:1228:1086:0:0:0:1',
          EndIp: '2001:b030:1228:1086:ffff:ffff:ffff:ffff',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 19,
      name: '2001:b030:1228:1086::',
      id: 29,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 2,
      IpVersion: 1,
      StartIp: '192.168.87.1',
      EndIp: '192.168.87.254',
      Prefix: null,
      DhcpPool: [],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 20,
      name: '192.168.87.X',
      id: 5,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 2,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:b030:1228:1067::',
      DhcpPool: [],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 21,
      name: '2001:b030:1228:1087::',
      id: 28,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 11,
      IpVersion: 1,
      StartIp: '192.168.2.1',
      EndIp: '192.168.2.254',
      Prefix: null,
      DhcpPool: [
        {
          IpRangeId: 25,
          StartIp: '192.168.2.30',
          EndIp: '192.168.2.254',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 22,
      name: '192.168.2.x',
      id: 25,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 11,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:b030:1228:1002::',
      DhcpPool: [
        {
          IpRangeId: 32,
          StartIp: '2001:b030:1228:1002:0:0:0:1',
          EndIp: '2001:b030:1228:1002:ffff:ffff:ffff:ffff',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 23,
      name: '2001:b030:1228:1002::',
      id: 32,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 11,
      IpVersion: 1,
      StartIp: '172.168.2.1',
      EndIp: '172.168.2.254',
      Prefix: null,
      DhcpPool: [
        {
          IpRangeId: 37,
          StartIp: '172.168.2.1',
          EndIp: '172.168.2.254',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 24,
      name: 'Pool 1',
      id: 37,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 17,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:b030:2030:0116::',
      DhcpPool: [],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 25,
      name: 'IPv6',
      id: 39,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 26,
      IpVersion: 1,
      StartIp: '192.168.16.1',
      EndIp: '192.168.16.254',
      Prefix: null,
      DhcpPool: [],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 26,
      name: '192.168.16.x',
      id: 41,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 26,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:4420:2222:ff02::',
      DhcpPool: [],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 27,
      name: '2001:4420:2222:FF02::',
      id: 43,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: false,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 27,
      IpVersion: 1,
      StartIp: '192.168.66.1',
      EndIp: '192.168.66.254',
      Prefix: null,
      DhcpPool: [
        {
          IpRangeId: 40,
          StartIp: '192.168.66.1',
          EndIp: '192.168.66.254',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 2,
      key: 28,
      name: '業務課VLAN66',
      id: 40,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: true,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 4,
      IpVersion: 1,
      StartIp: '192.168.67.1',
      EndIp: '192.168.67.254',
      Prefix: null,
      DhcpPool: [
        {
          IpRangeId: 1,
          StartIp: '192.168.67.1',
          EndIp: '192.168.67.100',
          EnableStaticIpBlock: true,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 3,
      key: 29,
      name: '192.168.67.X',
      id: 1,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: true,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 4,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:b030:1328:1067::',
      DhcpPool: [
        {
          IpRangeId: 31,
          StartIp: '2001:b030:1328:1067:0:0:0:1',
          EndIp: '2001:b030:1328:1067:ffff:ffff:ffff:ffff',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 3,
      key: 30,
      name: '2001:b030:1328:1067::',
      id: 31,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: true,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: true,
      type: 3,
      NetworkId: 6,
      IpVersion: 1,
      StartIp: '192.168.61.1',
      EndIp: '192.168.61.254',
      Prefix: null,
      DhcpPool: [
        {
          IpRangeId: 14,
          StartIp: '192.168.61.1',
          EndIp: '192.168.61.5',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        },
        {
          IpRangeId: 14,
          StartIp: '192.168.61.12',
          EndIp: '192.168.61.12',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        },
        {
          IpRangeId: 14,
          StartIp: '192.168.61.200',
          EndIp: '192.168.61.250',
          EnableStaticIpBlock: false,
          StartRawIpAddress: null,
          EndRawIpAddress: null,
          EnableOnlyAssignDHCPStaticIPPolicy: false,
          PoolType: 0
        }
      ],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 3,
      key: 31,
      name: '192.168.61.x',
      id: 14,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: true,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 6,
      IpVersion: 2,
      StartIp: null,
      EndIp: null,
      Prefix: '2001:b030:1328:1061::',
      DhcpPool: [],
      PrefixLength: 64,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 3,
      key: 32,
      name: '2001:b030:1328:1061::',
      id: 30,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    },
    {
      BrocastViolationIPs: null,
      ViolationType: 0,
      IsIpConflicted: false,
      HasUnauthorizedHost: true,
      HasImportantIpOffline: false,
      type: 3,
      NetworkId: 25,
      IpVersion: 1,
      StartIp: '192.168.100.1',
      EndIp: '192.168.100.254',
      Prefix: null,
      DhcpPool: [],
      PrefixLength: 0,
      EnableOnlyAssignDHCPStaticIPPolicy: false,
      SiteId: 3,
      key: 33,
      name: 'HQ',
      id: 38,
      loc: null,
      category: 'Range',
      isTreeExpanded: true
    }
  ],
  linkData: [
    { from: 0, to: 1 },
    { from: 0, to: 2 },
    { from: 0, to: 3 },
    { from: 0, to: 4 },
    { from: 0, to: 5 },
    { from: 2, to: 6 },
    { from: 2, to: 7 },
    { from: 3, to: 8 },
    { from: 3, to: 9 },
    { from: 2, to: 10 },
    { from: 2, to: 11 },
    { from: 2, to: 12 },
    { from: 2, to: 13 },
    { from: 3, to: 14 },
    { from: 2, to: 15 },
    { from: 2, to: 16 },
    { from: 3, to: 17 },
    { from: 6, to: 18 },
    { from: 6, to: 19 },
    { from: 7, to: 20 },
    { from: 7, to: 21 },
    { from: 10, to: 22 },
    { from: 10, to: 23 },
    { from: 10, to: 24 },
    { from: 13, to: 25 },
    { from: 15, to: 26 },
    { from: 15, to: 27 },
    { from: 16, to: 28 },
    { from: 8, to: 29 },
    { from: 8, to: 30 },
    { from: 9, to: 31 },
    { from: 9, to: 32 },
    { from: 14, to: 33 }
  ]
}

export { networkInfrastructure }
