<template>
  <div class="event-item-wrapper" :class="{ 'has-read': event.Read }">
    <div class="event-icon">
      <SvgIconFixedColor :icon="event.Icon" />
    </div>
    <div class="event-content">
      <p class="event-text event-time">{{ event.HappenedTime }}</p>
      <p class="event-text event-name">[{{ event.Name }}]</p>
      <p class="event-text event-detail">
        {{ event.Detail }}
      </p>
      <p class="event-text event-ip">IP: {{ event.IP }}</p>
      <p class="event-text event-mac">MAC: {{ event.MAC }}</p>
    </div>
    <div class="event-read-state">
      <span class="unread-dot"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
