<template>
  <button @click="restoreColumns" class="k-button mr-1">
    Restore hidden columns
  </button>
  <button @click="exportExcel" class="k-button">Export Excel</button>

  <Grid
    :style="{ height: '450px' }"
    :columns="columns"
    :data-items="result"
    :pageable="{ buttonCount: 4, pageSizes: [16, 32, 128, 255] }"
    :skip="skip"
    :take="take"
    :total="total"
    @pagechange="pageChangeHandler"
    :scrollable="true"
    :reorderable="true"
    @columnreorder="columnReorder"
    :sortable="true"
    :sort="sort"
    @sortchange="sortChangeHandler"
  >
    <template v-slot:myHeader="{ props }">
      <gridHeader
        :field="props.field"
        :title="props.title"
        @hidecolumn="hidecolumn"
      /> </template
  ></Grid>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import useGrid from '../composable/useGrid.js'
import { Grid } from '@progress/kendo-vue-grid'
import gridHeader from '../components/gridHeader.vue'

export default defineComponent({
  components: {
    Grid,
    gridHeader
  },
  setup() {
    const {
      columns,
      skip,
      take,
      dataItems,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      columnReorder,
      sortChangeHandler,
      exportExcel,
      restoreColumns,
      hidecolumn
    } = useGrid()

    onMounted(() => {
      dataItems.value = createRandomData(65536) as never[]
    })

    return {
      columns,
      skip,
      take,
      dataItems,
      result,
      total,
      sort,
      pageChangeHandler,
      columnReorder,
      sortChangeHandler,
      exportExcel,
      restoreColumns,
      hidecolumn
    }
  }
})
</script>

<style scoped></style>
