<template>
  <!-- 麵包屑只有一層時，自動隱藏 -->
  <ol class="breadcrumb" v-if="breadcrumbList.length > 1">
    <li
      class="breadcrumb-item"
      v-for="breadcrumb in breadcrumbList"
      :key="breadcrumb.text"
    >
      <!-- 當前頁面 or 無路徑時，只顯示文字 -->
      <span v-if="breadcrumb.active || !breadcrumb.pathName">
        {{ breadcrumb.text }}
      </span>
      <!-- 顯示連結 -->
      <router-link v-else :to="{ name: breadcrumb.pathName }">
        {{ breadcrumb.text }}
      </router-link>
    </li>
  </ol>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useBreadcrumb from '@/composable/useBreadcrumb'

export default defineComponent({
  setup() {
    const { breadcrumbList } = useBreadcrumb()

    return {
      breadcrumbList
    }
  }
})
</script>

<style scoped></style>
