import { createStore } from 'vuex'
import user from './modules/user'
import drag from './modules/drag'
import theme from './modules/theme'
import load from './modules/load'

export default createStore({
  modules: {
    user,
    drag,
    theme,
    load
  }
})
