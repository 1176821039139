
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    title: String,
    sortable: [Boolean, Object]
  },
  setup(props, ctx) {
    const hidecolumn = e => {
      ctx.emit('hidecolumn', e)
    }

    return {
      hidecolumn
    }
  }
})
