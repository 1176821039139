
import { defineComponent, ref } from 'vue'
import Sidebar from './components/Sidebar/index.vue'
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'

export default defineComponent({
  components: {
    Sidebar,
    Navbar,
    AppMain
  },
  setup() {
    const isSidebarHide = ref(false)

    const toggleSidebar = () => (isSidebarHide.value = !isSidebarHide.value)
    const showSidebar = () => (isSidebarHide.value = false)

    return {
      isSidebarHide,
      toggleSidebar,
      showSidebar
    }
  }
})
