
import { defineComponent } from 'vue'
import HelloKendoGrid from '../components/HelloKendoGrid.vue'

export default defineComponent({
  components: {
    HelloKendoGrid
  },
  setup() {
    return {}
  }
})
